/* You can add global styles to this file, and also import other style files */
@import '~jquery-nice-select/css/nice-select'; 
@import "~@ng-select/ng-select/themes/default.theme.css";
:not(app-scan)
/* @import "~angular2-multiselect-dropdown/themes/default.theme.css";
@import '~perfect-scrollbar/css/perfect-scrollbar.css';
@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-angular-schedule/styles/material.css'; */

.navbar-brand img {
	transform-origin: 5% 60%;
	transition: transform 3s, filter 3s ease-in-out;
}

.navbar-brand img:hover {
	filter: brightness(100%);
	transform: scale(2);
	position: absolute;
	cursor: zoom-in;
	z-index: 999;
	/* left: 10rem; */
	border-radius: 4px;
	box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
	scrollbar-width: auto;
	scrollbar-color: #ebebeb #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
	width: 12px;
}

*::-webkit-scrollbar-track {
	background: #ffffff;
}

*::-webkit-scrollbar-thumb {
	background-color: #cdcdcd;
	border-radius: 10px;
	border: 3px solid #ffffff;
}


body.modal-open {
	overflow: hidden !important;
}

.ps .ps__scrollbar-y-rail {
	left: 3px;
	right: auto;
}

.is-active {
	color: #0584f7 !important;
}

.auth-wrapper .auth-box {
	background: #fff;
	padding: 20px;
	box-shadow: 1px 0 20px rgba(0, 0, 0, .08);
	width: 100%;
	max-width: 450px;
	border-radius: 8px;
}

.auth-wrapper .auth-box .logo {
	text-align: center;
}

.has-float-label .form-control:placeholder-shown:not(:focus)+* {
	font-size: 90%;
	top: 1.2em;
}

.btn:focus,
.btn.focus {
	outline: 0 !important;
	box-shadow: none !important;
}

.has-float-label .form-control::placeholder {
	font-size: 85%;
}

.i-icon {
	position: absolute;
	right: 10px;
	top: 5px;
	color: #bbc6d0;
}

.i-icon:before,
.i-icon:before {
	font-size: 20px !important;
}

.btn {

	cursor: pointer !important;
}

@media (min-width: 768px) {
	#main-wrapper[data-layout=horizontal][data-sidebar-position=fixed] .page-wrapper {
		padding-top: 128px;
	}
}

@media (max-width: 768px) {
	.topbar .top-navbar .navbar-nav {
		flex-direction: row !important;
	}


	.py-10 {
		padding-top: 3rem !important;
		padding-bottom: 3rem !important;
	}

	.mini-sidebar .page-wrapper {
		margin-left: 0px !important;
	}

	/* .text-sm-left{text-align:left !important;} */
}


/*Sub Header css*/

.sub-header {
	width: 100%;
	float: left;
}

.sub-header ul {
	margin: 0;
	padding: 0;
	list-style: none;
	overflow: auto;
	background-color: #fff;
}

/* menu */

.sub-header .menu {
	clear: both;
	max-height: 0;
}

/* menu icon */

.sub-header .menu-icon {
	cursor: pointer;
	display: block;
	padding: 20px 20px;
	position: relative;
	user-select: none;
	margin: 0px;
	float: right;
}

.sub-header .menu-icon .navicon {
	background: #006937;
	display: block;
	height: 2px;
	position: relative;
	transition: 0.2s ease-out;
	width: 18px;
}

.sub-header .menu-icon .navicon:before,
.sub-header .menu-icon .navicon:after {
	background: #006937;
	content: '';
	display: block;
	height: 100%;
	position: absolute;
	transition: all .2s ease-out;
	width: 100%;
}

.sub-header .menu-icon .navicon:before {
	top: 5px;
}

.sub-header .menu-icon .navicon:after {
	top: -5px;
}

/* menu btn */

.sub-header .menu-btn {
	display: none;
}

.sub-header .menu-btn:checked~.menu {
	width: 100%;
	max-height: 100%;
	min-height: 200px;
	overflow: auto;
	-webkit-transition: 0.2s ease-out;
	transition: 0.2s ease-out;
	position: absolute;
	top: 60px;
	left: 0;
	z-index: 9;
	flex-direction: column;
	background: #f8f9fa;
	box-shadow: 0 1px 5px rgba(0, 0, 0, 0.05);
}

.sub-header .menu-btn:checked~.menu-icon .navicon {
	background: transparent;
}

.sub-header .menu-btn:checked~.menu-icon .navicon:before {
	transform: rotate(-45deg);
}

.sub-header .menu-btn:checked~.menu-icon .navicon:after {
	transform: rotate(45deg);
}

.sub-header .menu-btn:checked~.menu-icon:not(.steps) .navicon:before,
.sub-header .menu-btn:checked~.menu-icon:not(.steps) .navicon:after {
	top: 0;
}

@media (min-width: 62em) {
	.sub-header .menu {
		clear: none;
		max-height: none;
	}

	.sub-header .menu-icon {
		display: none;
	}

	.sub-header ul {
		overflow: hidden;
	}
}

@media (max-width: 1366px) {
	.sub-header li a {
		margin: 3px 3px;
	}
}

.intro_layers {
	-webkit-animation: bubble 5s linear infinite;
	animation: bubble 5s linear infinite;
	background-image: linear-gradient(to right top, #006937, #0098f3, #00a5ec, #00b1e1, #1dbbd5);
	display: flex;
	flex-direction: column;
	justify-content: center;
	max-width: 730px;
	padding: 80px 63px;
	position: relative;
	width: 100%;
	z-index: 1;
}

@keyframes bubble {

	0%,
	100% {
		border-radius: 64% 46% 37% 73% / 55% 58% 42% 45%;
	}

	25% {
		border-radius: 43% 47% 47% 38% / 43% 46% 64% 27%;
	}

	50% {
		border-radius: 48% 72% 44% 56% / 49% 40% 60% 51%;
	}

	75% {
		border-radius: 40% 60% 70% 30% / 47% 62% 38% 53%;
	}

	0%,
	100% {
		border-radius: 64% 46% 37% 73% / 55% 58% 42% 45%;
	}

	25% {
		border-radius: 43% 47% 47% 38% / 43% 46% 64% 27%;
	}

	50% {
		border-radius: 48% 72% 44% 56% / 49% 40% 60% 51%;
	}

	75% {
		border-radius: 40% 60% 70% 30% / 47% 62% 38% 53%;
	}
}

.content h1 {
	color: #006937;
}

.section-heading {
	text-transform: uppercase;
	color: #006937;
	font-weight: 600;
	text-align: center;
}

.py-10 {
	padding-top: 8rem;
	padding-bottom: 8rem;
}

.bg-custom {
	background: url(assets/images/map.png);
}

/* .features ul li{
  list-style-type: disc;
  padding: 0.7rem 0;
} */
.imgBox img {
	width: 600px;
}

.features .feature-icon {
	margin: 0 18px;
	-webkit-filter: drop-shadow(0 0 10px #eee);
	filter: drop-shadow(0 0 10px #eee);
	border-radius: 20px;
	background-color: #fff;
	-webkit-transition: 0.5s;
	transition: 0.5s;
	margin-bottom: 60px;
}

.features .feature-icon a {
	padding: 45px 25px;
	display: block;
	text-align: center;
}

.features .feature-icon:hover {
	-webkit-transition: 0.5s;
	transition: 0.5s;
	background-color: #006937;
}

.features .feature-icon h5 {
	padding-top: 10px;
}

.features .feature-icon:hover h5 {
	color: #fff;
}

.features .feature-icon:hover a img {
	-webkit-filter: brightness(0) invert(1);
	filter: brightness(0) invert(1);
}

/**Datatable css**/
table.dataTable.no-footer {
	border-bottom: 1px solid #e8e8e8 !important;
}

table.dataTable thead th,
table.dataTable thead td {
	border-bottom: 1px solid #e8e8e8 !important;
	font-size: 13px;
}

table.dataTable tbody td {
	font-size: 13px;
}

table.dataTable.row-border tbody th,
table.dataTable.row-border tbody td,
table.dataTable.display tbody th,
table.dataTable.display tbody td {
	border-top: 1px solid #e8e8e8 !important;
}

.dataTables_wrapper .dataTables_filter input {
	padding: .175rem .75rem !important;
	color: #495057 !important;
	background-color: #fff !important;
	background-clip: padding-box !important;
	border: 1px solid #e8e8e8 !important;
	border-radius: .25rem !important;
	-webkit-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out !important;
	transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out !important;
	box-shadow: 1px 0 5px rgba(0, 0, 0, .05) !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
	color: #fff !important;
	border: 1px solid #b9d0c5 !important;
	background: rgba(0, 105, 55, 0.1) !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
	padding: 0.2em 0.8em !important;
	border-radius: 5px !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.disabled,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
	color: initial !important;
	font-size: 14px;
}

.dataTables_wrapper .dataTables_length,
.dataTables_wrapper .dataTables_filter,
.dataTables_wrapper .dataTables_info,
.dataTables_wrapper .dataTables_processing,
.dataTables_wrapper .dataTables_paginate {
	color: initial !important;
	font-size: 14px !important;
}

.dataTables_length label {
	text-transform: capitalize;
}

.dataTables_length select {
	border: 1px solid #e8e8e8;
	min-width: 55px;
	border-radius: 4px;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background: url('assets/images/custom-select.png') right 0.45rem center no-repeat;
	background-size: 10px;
	padding-left: 6px;
	margin-left: 3px;
	margin-right: 3px;
}

.has-float-label .custom-select-lg {
	font-size: 0.9rem;
}

.custom-select-lg {
	height: calc(1.5em + 1rem + 8px) !important;
}

input[type=date].form-control-lg {
	font-size: 0.9rem;
	height: calc(1.5em + 1rem + 10px) !important;
}

.form-control-lg {
	font-size: 0.9rem !important;
	height: calc(1.5em + 1rem + 10px) !important;
}

.sidebar-nav ul li.active {
	background: #118dd2;
	border-radius: 20px 0px 0px 20px;
}

.sidebar-nav ul li.active a:hover {
	color: #fff !important;
}

.sidebar-nav ul li.active a {
	color: #fff !important;
}

.sidebar-nav ul li ul {
	background: transparent !important;
}

.sidebar-nav ul li ul li:hover {
	background: #126da0 !important;
}

#sidebarnav li.active>a {
	/* background: rgba(255,255,255,.1); */
	/* background: #eee8a9; */
	/* background: #a3e8ff;
  /* border-radius: 100px 0px 0px 100px; */
	border-bottom: 1px solid #006937;
}

#sidebarnav li.active ul li a {
	background: transparent;
	color: white;
}

#sidebarnav li.active ul li a.active {
	background: #0983c7;
	color: #a3e8ff;
	/* border-radius: 100px 0 0 100px; */
}

#sidebarnav li:hover {
	background: #0983c7;
	/* border-radius: 20px 0px 0px 20px; */
}

#sidebarnav li.active:hover {
	background: #0983c7;
	/* border-radius: 20px 0px 0px 20px; */
}

#sidebarnav li ul li:hover {
	background: #0983c7;
	/* border-radius: 20px 0px 0px 20px; */
}

.sidebartoggler i {
	background: none;
	width: 30px;
	height: 30px;
	display: block;
	text-align: center;
	line-height: 37px;
	color: #000;
	border-radius: 50%;
	/* box-shadow: 0 8px 16px 0 rgba(34, 93, 169, 0.31); */
	transition: 0.2s ease-in-out;
	transform-origin: right top;
}

.sidebartoggler:hover i {
	border-radius: 50%;
}

.table-striped tbody tr:nth-of-type(2n+1) {
	background-color: #f5f9f7 !important
}

table.dataTable.hover tbody tr:hover,
table.dataTable.display tbody tr:hover {
	background-color: #f5f9f7 !important
}

.dt-button {
	color: #006937 !important;
	border: 1px solid #b9d0c5 !important;
	background: rgba(0, 105, 55, 0.1) !important;
	border-radius: 100px !important;
	/* box-shadow: 0 2px 2px 0 rgba(66, 165, 245, 0.14), 0 3px 1px -2px rgba(66, 165, 245, 0.2), 0 1px 5px 0 rgba(66, 165, 245, 0.12); */
	font-size: 13px;
	min-width: 60px;
	padding: 4px;
	transition: 0.2s ease-in-out;
}

.dt-button:hover {
	background: #006937 !important;
	color: #fff !important;
}

.dt-buttons {
	display: inline-block;
}

.badge-warning {
	color: #f4bd0e !important;
	background-color: #fef8e4 !important;
	border-color: #fef8e4 !important;
}

.badge-danger {
	color: #e85347 !important;
	background-color: #fceceb !important;
	border-color: #fceceb !important;
}

.badge-success {
	color: #1ee0ac !important;
	background-color: #e6fcf6 !important;
	border-color: #e6fcf6 !important;
}

.badge-secondary {
	color: #364a63 !important;
	background-color: #e9ebee !important;
	border-color: #e9ebee !important;
}

.badge-primary {
	color: #6576ff !important;
	background-color: #eef0ff !important;
	border-color: #eef0ff !important;
}

.badge-info {
	color: #09c2de !important;
	background-color: #e4f8fb !important;
	border-color: #e4f8fb !important;
}

.badge {
	padding: 0.5em 1em !important;
	font-size: 90% !important;
}

.has-float-label .ss:placeholder-shown:not(:focus)::placeholder {
	opacity: 1 !important;
}

.has-float-label .form-control:placeholder-shown:not(:focus)+* {
	opacity: 1 !important;
}

.navbar-light .navbar-nav .nav-link {
	color: #414756;
	text-transform: uppercase;
}

.custom-control-input:checked~.custom-control-label::before {
	border-color: #006937 !important;
	background-color: #006937 !important;
}

.switchPermission li {
	padding: 1rem;
	background: #f5f6f8;
	margin: 1rem 0;
	box-shadow: 0 0 10px 0 rgba(228, 231, 235, 0.5);
	transition: 0.2s ease-in-out;
}

.switchPermission li:hover {
	background: #f4f9ff;
	transform: scale(1.02);
}

.topbar .dropdown-menu {
	-webkit-transform-origin: 50% 0;
	transform-origin: 50% 0;
	-webkit-transform: scale(0.75) translateY(-21px);
	transform: scale(0.75) translateY(-21px);
	-webkit-transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
	transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
	width: 200px;
}

.topbar ul.dropdown-user {
	width: unset;
}

.notification-icon li a i.mdi {
	color: #006937;
	font-size: 1.3rem;
	width: 40px;
	height: 40px;
	line-height: 40px;
	border-radius: 50%;
	background: rgba(0, 105, 55, 0.1) !important;
	display: block;
	text-align: center;
	transition: 0.2s ease-in-out;
}

.notification-icon li a:hover i.mdi {
	transform: scale(1.1);
	color: #ffffff;
	background: #4fac48 !important;

}

.topbar .top-navbar .mailbox {
	width: 350px;
}

.topbar .top-navbar .mailbox ul li {
	list-style: none;
}

.mailbox ul li .drop-title {
	font-weight: 500;
	padding: 5px 20px;
	border-bottom: 1px solid rgba(120, 130, 140, .13);
}

.mailbox .message-center {
	height: 350px;
	overflow: auto !important;
	position: relative;
}

.mailbox .message-center a {
	display: -webkit-box;
	display: flex;
	-webkit-box-align: start;
	align-items: flex-start;
	text-decoration: none;
	padding: 9px 15px;
}

.mailbox .message-center a .mail-contnet {
	word-break: break-all;
	width: 90%;
	display: inline-block;
}

.mailbox .message-center a .mail-contnet .mail-desc,
.mailbox .message-center a .mail-contnet .time {
	display: block;
	margin: 1px 0;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	color: #8a8a8a;
}

.action-tool-bar {
	position: relative;
	z-index: 1;
}

.action-tool-bar a {
	margin: 0 3px;
}

.action-tool-bar li:first-child {
	margin-left: 0px;
}

.action-tool-bar li:last-child {
	margin-right: 0px;
}

.btn-light-info {
	background: rgb(79, 172, 72) !important;
	outline: 0;
	font-size: 0.875rem !important;
	box-shadow: none;
	-webkit-transition: 0.3s ease-in-out !important;
	transition: 0.3s ease-in-out !important;
	color: #fff !important;
	border: 1px solid #4fac48 !important;
	/* border-radius: 100px !important; */
}

.btn-light-info:hover {
	background: #139709 !important;
	color: #fff !important;
}

.action-tool-bar .mdi-set,
.action-tool-bar .mdi::before {
	font-size: 18px;
}

.defaultdark .topbar {
	width: 240px !important;
}

.mini-sidebar .topbar {
	width: 70px !important;
}

.mini-sidebar.nav-link {
	padding-left: 3px !important;
}

@media (max-width: 576px) {
	.action-tool-bar li {
		margin: 3px 3px;
	}
}

.card-no-border .card-header {
	background: #fff;
	border-bottom: 1px solid #ebebeb;
}

.card-header h5 {
	color: #006937;
}

.card-footer {
	padding: 0.75rem 1.25rem;
	background-color: #f4f9ff !important;
	border-top: 1px solid rgb(214, 221, 230) !important;
}

label {
	font-size: 0.875rem;
}

.modal-header,
.modal-footer {
	background: #ffffff !important;
	padding: 10px 16px !important;
}

.modal-header {
	background-color: #118dd2 !important;
}

.modal-body {
	background: #f7f9f6;
}

.files::before {
	position: absolute;
	bottom: 30px;
	left: 0;
	pointer-events: none;
	width: 90%;
	right: 0;
	text-align: center;
	height: 75px;
	content: "Drop files here to upload or choose file";
	display: block;
	margin: 0 auto;
	background-image: url(assets/images/files-img.png);
	background-color: #fff;
	line-height: 0px;
	background-repeat: no-repeat;
	background-size: 150px;
	background-position: center;
	font-size: 13px;
}

.no-before::before {
	display: none !important;
	content: "" !important;
}

input[type="file"] {
	display: block;
}

.files input {
	outline: 2px dashed #92b0b3;
	outline-offset: -10px;
	-webkit-transition: outline-offset .15s ease-in-out, background-color .15s linear;
	transition: outline-offset .15s ease-in-out, background-color .15s linear;
	padding: 50px 0px 75px 35%;
	text-align: center !important;
	margin: 0;
	display: inline-block !important;
	margin: 5px 0;
	width: 100% !important;
}

.box-shadow-bordered {
	background: #f4f9ff;
	border: 1px dashed #bebebe;
	padding: 10px;
	border-radius: 4px;
	min-height: 155px;
	font-size: 13px;
}

.header_style_2 {
	font-size: 13px;
	margin: 5px 0;
	font-weight: 500;
	color: #006937;
	text-transform: uppercase;
}

.zIndexUp {
	position: relative;
	z-index: 9;
}

.barcode {
	width: 100%;
	height: 250px;
	border: 1px dashed #ccc;
	margin-bottom: 15px;
}

.sidebar-nav .has-arrow::after {
	position: absolute;
	content: '';
	width: 7px;
	height: 7px;
	border-width: 2px 0 0 2px;
	border-style: solid;
	border-color: #000;
	right: 1.1rem;
	-webkit-transform: rotate(-135deg) translate(0, -50%);
	transform: rotate(-135deg) translate(0, -50%);
	-webkit-transform-origin: top;
	transform-origin: top;
	top: 24px;
	transition: .3s ease-out;
}

.sidebar-nav .active>.has-arrow::after,
.sidebar-nav .has-arrow[aria-expanded="true"]::after,
.sidebar-nav li>.has-arrow.active::after {
	-webkit-transform: rotate(-315deg) translate(0, -50%);
	-ms-transform: rotate(-315deg) translate(0, -50%);
	-o-transform: rotate(-315deg) translate(0, -50%);
	transform: rotate(-315deg) translate(0, -50%);
	top: 55%;
	width: 7px;
	border-color: #ffffff;
}

.sidebar-nav .has-arrow {
	position: relative;
}

.btn-trigger {
	position: relative;
}

.btn-trigger:hover::before,
.btn-trigger:focus::before,
.btn-trigger.active:not(.revarse)::before {
	opacity: 1;
	height: 40px;
	width: 40px;
}

.btn-trigger::before {
	position: absolute;
	height: 20px;
	width: 20px;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	transform-origin: 50% 50%;
	content: '';
	z-index: 0;
	background: #e5f0eb;
	color: #006937;
	border: 1px solid #cbdbbc;
	border-radius: 50%;
	opacity: 0;
	transition: all .3s;
}

.btn-trigger span.mdi {
	color: #526484;
	position: relative;
	z-index: 2;
}

table>tbody>tr>td {
	vertical-align: middle !important;
}

.alert {
	padding: 0.32rem 1.25rem !important;
}

.alert-dismissible .close {
	padding: 0.32rem 1.25rem !important;
}

.round-nice-select {
	border-radius: 100px;
	color: #006937 !important;
	border: 1px solid #cbdbbc !important;
	border-radius: 100px !important;
	background: rgba(0, 105, 55, 0.1) !important;
	box-shadow: none !important;
}

.btn-info {
	color: #fff !important;
	border: 1px solid #4fac48 !important;
	border-radius: 100px !important;
	background: rgb(79, 172, 72) !important;
	box-shadow: none !important;
	min-width: 70px;
}

.btn-info:hover {
	color: #fff !important;
	border: 1px solid #139709 !important;
	background: #139709 !important;
}

.btn-warning {
	border-radius: 100px;
	color: #ffb22b !important;
	border: 1px solid #fbdaa0 !important;
	border-radius: 100px !important;
	background: rgba(255, 178, 43, 0.2) !important;
	box-shadow: none !important;
	min-width: 70px;
}

.btn-warning:hover {
	color: #fff !important;
	border: 1px solid #ffb22b !important;
	background: #ffb22b !important;
}

.btn-secondary {
	border-radius: 100px !important;
	box-shadow: none !important;
	min-width: 70px;
}

.round-nice-select:after {
	border-bottom: 2px solid #006937;
	border-right: 2px solid #006937;
}

.btn-danger,
.btn-primary,
.btn-success {
	border-radius: 100px !important;
}

.btn-danger:focus,
.btn-primary:focus {
	border-radius: 100px !important;
	color: #fff;
}

.customTabs {
	background: #fff;
	border: 2px solid #ccdcee;
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
	margin-bottom: 15px;
	border-radius: 12px;
	overflow: hidden;
}

.customTabs ul {
	background: #fff;
}

.customTabs ul li {
	display: block;
}

.customTabs li a {
	display: block;
	padding: 10px 15px;
	background: #ffffff;
	color: #526484;
	transition: 0.2s ease-in-out;
	border-bottom: 3px solid transparent;
}

.customTabs li:hover a {
	border-bottom: 3px solid #006937;
}

.customTabs li a span {
	width: 30px;
	height: 30px;
	line-height: 30px;
	border-radius: 50%;
	text-align: center;
	display: inline-block;
	transition: 0.2s ease-in-out;
}

.customTabs li:hover a span {
	background: #006937;
	color: #fff;
}

.customTabs li:hover a span::before {
	transform: scale(1.1);
}

.profilePic {
	position: relative;
	background: #126da0;
	min-height: 150px;
	max-height: 150px;
}

.imageContainer {
	position: absolute;
	left: 0px;
	right: 0px;
	top: 80px;
	margin: auto;
	width: 130px;
	height: 130px;
	object-fit: cover;
}

.profilePic img {
	width: 130px;
	height: 130px;
}

.icon-circle-xxl,
.icon-circle.xxl {
	height: 80px;
	width: 80px;
	font-size: 40px;
}

.icon-circle {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	background: #6576ff;
	background-color: rgb(101, 118, 255);
	color: #fff;
	font-size: 18px;
	height: 36px;
	width: 36px;
}

.icon-bg-danger {
	background-color: #e85347 !important;
}

#progressbar {
	margin-bottom: 30px;
	overflow: hidden;
	color: #455A64;
	padding-left: 0px;
	margin-top: 30px
}

#progressbar li {
	list-style-type: none;
	font-size: 13px;
	width: 25%;
	float: left;
	position: relative;
	font-weight: 400
}

#progressbar .step0:before {
	font-family: "Material Design Icons";
	content: '\f12f';
	color: #fff
}

.mat-sort-header-arrow {
	color: #f5eded;
}

.mat-sort-header-arrow {
	opacity: 0 !important;
	color: #4fac48;
}

table.mat-table>tbody>tr.mat-row:nth-child(even) {
	background-color: #eee !important;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
	border-radius: 4px 4px 4px 4px !important;
	/* padding: 0 0.75em 0 0.75em !important; */
	padding: 0px!important;
	border: 1px solid #ccc;
}

.mat-input-element {
	height: 30px;
	font-size: 14px;
	font-weight: 500;
	font-family: 'Open Sans', sans-serif !important;
}

.mat-form-field-infix .mdi-magnify {
	margin-top: 5px;
	margin-right: -4px
}

table tbody tr:last-child td .dropdown-menu {
	top: auto !important;
	bottom: calc(100% + 25px);
}

.mat-paginator-container {
	margin: -15px 0;
}

.card .table-responsive {
	overflow-y: hidden;
}


#sidebarnav li a img.img-thumbnail {
	border-radius: 50%;
	height: 40px;
	width: 40px;
}

table .mat-sort-header-content {
	text-align: left;
	color: #222 !important;
	letter-spacing: 0.5px;
	font-weight: 600 !important;
	margin-right: -18px;
}

table.p-sheet .mat-sort-header-content {
	display: grid;
	text-align: center;
	width: 100%;
	color: #222 !important;
	letter-spacing: 0.5px;
	font-weight: 600 !important;
	margin-right: -23px;
}

table {
	font-family: 'Open Sans', sans-serif !important;
}

table .mat-header-cell {
	font-size: 13px;
	font-weight: 500;
	font-family: 'Open Sans', sans-serif !important;
}

.table thead th {
	vertical-align: middle !important;
	font-family: 'Open Sans', sans-serif;
}
svg.bi.bi-caret-down-fill.ng-star-inserted {
    color: red;
}
svg.bi.bi-caret-right-fill.ng-star-inserted {
    color: blue;
}

.treeview12{
	display: none;
	color: orange;
  }
  .treeview-item.keep-expand-icon .treeview-toggle-icon {
	display: inline-block;
	color: orange;
  }
table td.mat-cell:first-of-type {
	padding-left: 4px;
}

table td.mat-cell:last-of-type {
	padding-right: 10px;
}

.table tbody td {
	padding: 8px 10px;
	font-weight: 400;
	color: #000000;
	font-size: 13px;
}

table tr td .dropdown-menu .dropdown-item {
	padding: 4px 12px;
	font-size: 13px;
	font-weight: 300;
}

table tr td .dropdown-menu {
	min-width: 6.5rem;
	padding: 0.25rem 0;
	right: 0px !important;
	left: auto !important;
}

.mat-form-field-underline {
	display: none;
}

.mat-form-field-wrapper {
	padding-bottom: 0px !important;
}

.mat-table .mat-form-field-wrapper {
	padding-bottom: 0px;
}

.mat-table input[type=text] {
	height: auto;
	padding-top: 6px;
	padding-bottom: 6px;
}

.mat-form-field-appearance-fill .mat-form-field-infix {
	padding: 0px!important;
	border-top: none;
	height: 36px !important;
	display: flex;
	align-items: center;
}


.mat-input-element {
	height: 30px;
	font-size: 14px;
	font-weight: 500;
	font-family: 'Open Sans', sans-serif !important;
}

.mat-form-field-infix .mdi-magnify {
	margin-top: 5px;
	margin-right: -4px
}

table tbody tr:last-child td .dropdown-menu {
	top: auto !important;
	bottom: calc(100% + 25px);
}

.mat-paginator-container {
	margin: -15px 0;
}

.card .table-responsive {
	overflow-y: hidden;
}

.mat-form-field-wrapper-pb-0 .mat-form-field-wrapper {
	padding-bottom: 0 !important;
}

.list-area {
	word-break: break-all !important;
}

.mat-option {
	font-size: 0.875rem !important;
}

#progressbar li:before {
	width: 25px;
	height: 25px;
	line-height: 25px;
	display: block;
	font-size: 16px;
	background: #C5CAE9;
	border-radius: 50%;
	margin: auto;
	padding: 0px;
	position: relative;
	z-index: 2;
}

#progressbar li:after {
	content: '';
	width: 100%;
	height: 6px;
	background: #C5CAE9;
	position: absolute;
	left: 0;
	top: 10px;
	z-index: -1
}

#progressbar li:last-child:after {
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;
	position: absolute;
	left: -50%;
	z-index: 1;
}

#progressbar li:nth-child(2):after,
#progressbar li:nth-child(3):after {
	left: -50%;
	z-index: 1;
}

#progressbar li:first-child:after {
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
	position: absolute;
	left: 50%;
	z-index: 1;
}

#progressbar li:last-child:after {
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px
}

#progressbar li:first-child:after {
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px
}

#progressbar li.active:before,
#progressbar li.active:after {
	background: #006937
}

#progressbar li.active:before {
	font-family: "Material Design Icons";
	content: '\f12c';
}

table>tbody>tr>td a.btn {
	font-size: 1.3rem;
}

ngx-recaptcha2 {
	text-align: center;
	margin: auto;
	max-width: 300px;
	display: block;
}

.bg-light {
	background-color: #f8f9fa !important;
	border: 0px !important;
}

.sticky {
	position: sticky;
	position: -webkit-sticky;
	top: 0;
}

ngx-dropzone {
	padding: 0.5rem;
}

.booking-documents {
	display: flex;
	flex-flow: row;
	width: 100%;
}

.dropzone-wrapper {
	box-sizing: border-box;
	overflow: hidden;
	max-width: 200px;
	width: 100%;
	margin: 0 1rem 0 0;
}

.document-files-wrapper {
	width: 100%;
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
	grid-auto-rows: 1fr;
	grid-gap: 10px;
}

.document-files {
	position: relative;
	width: 100%;
	background-color: #ffffff;
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1);
	border-radius: 12px;
	text-align: center;
}

.document-files .document_content h4 {
	margin: 0;
	padding-bottom: 10px;
	border-bottom: 1px solid #f4f9ff;
	font-size: 1rem;
}

.document-files .document_content p {
	font-size: 12px;
	line-height: 14px;
	margin: 10px 0 13px 0;
}

.document-icon a {
	display: block;
	margin-bottom: 0.875rem;
}

.document-icon a img {
	width: 35px;
}

/**start Booking css**/

.route-block {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-ms-flex-item-align: center;
	align-self: center;
	padding: 30px 0 16px 0;
	width: 100%;
}

.route-block .wrapper-box-shadow {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-shadow: 0 0 20px rgba(105, 126, 157, .1);
	box-shadow: 0 0 20px rgba(105, 126, 157, .1);
	margin-right: 10px;
	border-radius: 5px;
	width: 100%;
	background-color: #fff;
}

.route-block-item {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	border-right: 1px solid #ecf2f9;
	width: 20%;
	position: relative;
}

.route-block .top-title {
	position: absolute;
	top: -25px;
	left: 2px;
	font-size: 12px;
	line-height: 14px;
	text-transform: uppercase;
}

.route-block-list {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	list-style-type: none;
	margin: 0;
	padding: 0;
	width: 100%;
}

.route-block-list li {
	width: 100%;
	height: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-ms-flex-item-align: center;
	align-self: center;
	border-right: 1px solid #ecf2f9;
}

.route-block-list li a {
	position: relative;
	color: #15354e;
	width: 100%;
	height: 100%;
	text-decoration: none;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	cursor: pointer;
	transition: 0.2s all ease-in-out;
}

.route-block-list li:first-child a {
	border-radius: 5px 0 0 5px;
}

.route-block-list li:last-child {
	border-right: none;
}

.route-list-active {
	background-color: #006937;
	color: #fff !important;
}

.route-block-list li a span.mdi {
	font-size: 1.3rem;
}

.route-block-left,
.route-block-right {
	width: 35%;
	position: relative;
}

.route-block .pol {
	position: relative;
	width: 100%;
	height: 100%;
}

.route-block .pol i {
	position: absolute;
	left: 10px;
	top: 48%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	font-size: 24px;
	font-weight: 500;
	color: #006937;
	font-style: normal;
}

.route-block input {
	padding: 0 10px 0 35px;
	width: 100%;
	font-size: 17px;
	height: 56px;
	border-radius: 3px !important;
	background-color: transparent;
	border: 1px solid transparent;
	color: #15354e;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	-webkit-box-shadow: none;
	box-shadow: none;
	outline: 0;
}

.route-block .btn-group {
	background: #fff;
	height: 100%;
	width: 100%;
}

.route-block .btn-group .btn {
	color: #848484;
	background: #fff;
	padding: 0px;
	box-shadow: none;
}

.route-block .dropdown-menu.show {
	display: block;
	width: 100%;
	padding: 0px;
	border: 1px solid #ebebeb;
}

.route-block .dropdown-item.active,
.route-block .dropdown-item:active {
	background-color: #006937;
}

.swap-places {
	position: absolute;
	z-index: 26;
	top: 49%;
	right: -13px;
	width: 26px;
	height: 26px;
	padding: 7px;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	cursor: pointer;
	-webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, .2);
	box-shadow: 0 2px 6px rgba(0, 0, 0, .2);
	border-radius: 50%;
	background-color: #fff;
}

.swap-places span {
	width: 13px;
	height: 6px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.swap-places span:first-child {
	-webkit-transform: rotate(.5turn) translateX(0);
	transform: rotate(.5turn) translateX(0);
}

.swap-places svg {
	width: 13px;
	height: 6px;
	fill: #006937;
}

.route-block-right {
	border-right: 1px solid #ecf2f9;
}

.route-block .pod input[type="text"] {
	padding-left: 45px;
}

.route-block .pod i {
	position: absolute;
	left: 10px;
	top: 48%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	font-size: 24px;
	font-weight: 500;
	color: #006937;
	font-style: normal;
}

.route-block .pod i {
	left: 20px;
}

.data-block-wrapper {
	position: relative;
	width: 30%;
	border-right: 1px solid #ecf2f9;
}

.data-block-wrapper .data-block {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	width: 100%;
	height: 100%;
	position: relative;
}

.dropdown-shipment {
	position: relative;
	flex-basis: 15%;
}

.dropdown-shipment>a {
	width: 100%;
	height: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 0 0 0 15px;
	border-radius: 0 5px 5px 0;
	color: #15354e;
}

.dropdown-shipment i {
	margin-right: 10px;
	font-size: 20px;
	line-height: 0;
	display: inline-block;
	vertical-align: inherit;
	color: #c7d3de;
}

.route-block .btn {
	padding: 0 45px;
	border-radius: 5px;
	color: #fff;
	background: #006937;
	font-size: 18px;
	font-weight: 500;
	height: 100%;
	width: 100%;
	-webkit-box-shadow: 0 3px 20px rgba(105, 126, 157, .3);
	box-shadow: 0 3px 20px rgba(105, 126, 157, .3);
	opacity: 1;
}

.route-block .btn:hover {
	opacity: .9;
}

.cost-top {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
}

.number-of-containers .cost-top {
	padding: 0;
	border-top: none;
}

.cost-top .persons_quantity {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	width: 100%;
	margin: 0;
}

.cost-top .quantity_wrap {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	color: #15354e;
	font-weight: 500;
	margin-bottom: 5px;
}

.quantity_wrap dt {
	font-size: 14px;
	text-transform: capitalize;
	font-weight: 400;
}

.quantity_wrap dd:last-child {
	margin-left: auto;
	margin-bottom: 0;
}

.quantity_wrap ul.number {
	list-style-type: none;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin: 0;
	padding: 0;
}

.quantity_wrap ul.number {
	list-style-type: none;
}

.quantity_wrap .minus,
.quantity_wrap .plus {
	width: 20px;
	height: 20px;
	margin: 0 3px;
	font-size: 10px;
	border-radius: 15px;
	color: #15354e;
	background-color: #f2f6f8;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	cursor: pointer;
}

.quantity_wrap ul li input {
	width: 25px;
	padding: 0 0 2px 0;
	color: #15354e;
	font-weight: 400;
	border: none;
	text-align: center;
}

.user-card {
	display: flex;
	align-items: center;
}

.followTabs li a {
	margin-right: 3px;
	height: 30px;
	border-radius: 5px;
	padding: 5px 10px;
	background-color: #007853;
	text-align: center;
	display: flex;
	color: #fff;
	line-height: 21px;
	font-size: 12px;
	font-weight: 600;
}

.tablink.active {
	background-color: #0ea677;
}

.user-avatar,
[class^="user-avatar"]:not([class*="-group"]) {
	border-radius: 50%;
	height: 40px;
	width: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff;
	background: transparent;
	border: 1px solid #ebebeb !important;
	font-size: 14px;
	font-weight: 500;
	letter-spacing: 0.06em;
	flex-shrink: 0;
	position: relative;
}

.user-avatar img,
[class^="user-avatar"]:not([class*="-group"]) img {
	border-radius: 50%;
}

.user-avatar+.user-name,
[class^="user-avatar"]:not([class*="-group"])+.user-name {
	margin-left: 0.75rem;
}

.user-name {
	max-width: calc(100% - 50px);
}

.user-name .lead-text {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	font-weight: 400;
}

.route-time-block .route {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.route .block-center {
	-webkit-box-flex: 1;
	-ms-flex: 1 1 150px;
	flex: 1 1 150px;
	text-align: center;
}

.route>div {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	width: 100%;
	position: relative;
}

.place-wrapp {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.route .a-place {
	text-align: left;
}

.route .b-place {
	text-align: right;
}

.route .a-place,
.route .b-place {
	width: 200px;
}

.route .a-place,
.route .b-place {
	width: 140px;
	color: #15354e;
	font-weight: 500;
	font-size: 14px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.route>div:last-child .route-icon {
	position: relative;
	text-align: center;
	font-size: 2rem;
	color: #006937;
}

.route .block-center .route-icon {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin-top: auto;
}

.route .line {
	width: 100%;
	height: 2px;
	background-color: #ccc;
	position: relative;
	margin-bottom: 10px;
}

.block-import.block-truck.active>.route>.block-center .line {
	background-color: #006937 !important;
}

.route>div .line>i {
	width: 14px;
	height: 14px;
	border: 2px solid #006937;
	border-radius: 10px;
	position: absolute;
	top: -6px;
	left: 0;
	background-color: #fff;
	cursor: pointer;
	z-index: 2;
	-webkit-box-shadow: 0 0 0 3px #fff;
	box-shadow: 0 0 0 3px #fff;
}

.route .line>i>i {
	width: 4px;
	height: 4px;
	background-color: #006937;
	border-radius: 10px;
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	cursor: pointer;
	z-index: 2;
}

.route>div:last-child .line>i:last-of-type {
	width: 14px;
	height: 14px;
	border: 1px solid #006937;
	border-radius: 10px;
	position: absolute;
	top: -6px;
	right: 0;
	left: auto;
	background-color: #fff;
	cursor: pointer;
	z-index: 2;
	-webkit-box-shadow: 0 0 0 3px #fff;
	box-shadow: 0 0 0 3px #fff;
}

.search-select-input {
	cursor: pointer;
}

.search-select-input .c-btn {
	min-height: 31px;
	line-height: 1;
	cursor: pointer;
}

@media screen and (max-width:991px) {
	.route-block {
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		padding: 10px 0;
	}

	.route-block .wrapper-box-shadow {
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-box-shadow: none;
		box-shadow: none;
		background-color: transparent;
		margin: 0;
	}

	.route-block-item {
		width: 100%;
		margin-bottom: 5px;
		border-right: none;
	}

	.route-block-list {
		max-width: 300px;
		margin: 0 auto;
		margin-bottom: 5px;
		background-color: #fff;
		border-radius: 5px;
	}

	.route-block-left {
		width: 50%;
		border-right: none;
	}

	.route-block-left {
		margin-bottom: 5px;
	}

	.route-block-right {
		width: 49%;
		margin-left: 5px;
	}

	.route-block-right {
		margin-bottom: 5px;
		border-right: none;
	}

	.data-block-wrapper {
		width: 50%;
		margin-bottom: 5px;
		margin-right: 5px;
	}

	.dropdown-shipment {
		width: 49%;
		flex-basis: 49%;
		margin-bottom: 5px;
		background-color: #fff;
		border-radius: 0 5px 5px 0;
	}

	.route-block-btn {
		width: 100%;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.route-block .top-title {
		display: none;
	}

	.route-block-list li a {
		padding: 10px 11px;
	}

	.route-block input[type="text"] {
		padding: 0 0 0 50px !important;
		background-color: #fff;
	}
}

@media screen and (max-width:567px) {
	.route-block-left {
		width: 100%;
		border: none;
	}

	.route-block-right {
		width: 100%;
		margin-left: 0;
	}

	.data-block-wrapper {
		margin-right: 2px;
	}

	.route-block-list {
		max-width: 100%;
	}
}

/**end Booking css**/
.nowrap {
	white-space: nowrap;
}

.modal .form-control,
.modal .nice-select {
	background-color: #fff;
	border: solid 1px #ebebeb;
}

.logout {
	display: block;
	background: #527931;
	padding: 0.7rem;
	color: #fff;
	font-weight: bold;
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	text-align: center;
}

.logout .mdi {
	font-size: 1rem;
}

.logout-btn {
	display: block;
	padding: 15px 25px;
	color: #fff;
	text-align: left;
	font-size: 1rem;
	background: #698e48;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
}

.feeds {
	margin: 0px;
	padding: 0px;
}

.btn-new-light-info {
	background-color: #cbe2ff;
	border-color: #cbe2ff;
}

.text-green {
	color: #006937;
}

.selected-list .c-list .c-token {
	background: transparent !important;
	padding: 4px 12px 4px 0px !important;
	margin-top: 0 !important;
}

.selected-list .c-list .c-token .c-label {
	color: #3c3c3c !important;
	line-height: 1.2;
}

.c-btn {
	min-height: 36px !important;
	max-height: 36px !important;
	border: solid 1px #ccc;

}

/* .selected-list .c-btn {
    width: 100%;
    padding: 3px 10px!important;
} */

.input-group-text {
	line-height: 0.5 !important;
}

table.dataTable tbody th,
table.dataTable tbody td {
	border-bottom: 1px dotted #000 !important;
}

.form-control {
	font-weight: bold;
}

.radio_container {
	display: flex;
	justify-content: space-around;
	align-items: center;
	background-color: #e6e6e6;
	width: 350px;
	height: 37px;
	border-radius: 9999px;
	box-shadow: inset 0.5px 0.5px 2px 0 rgba(0, 0, 0, 0.15);
	margin: 0px auto;
}

.radio_container input[type="radio"] {
	appearance: none;
	display: none;
}

.mylabel {

	font-size: 16px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: inherit;
	width: 167px;
	height: 33px;
	text-align: center;
	border-radius: 9999px;
	overflow: hidden;
	transition: linear 0.3s;
	color: #3b3b3bde;
	margin-bottom: 0px;
	cursor: pointer;
}

.radio_container input[type="radio"]:checked+label {
	background-color: #126da0;
	color: #f1f3f5;
	font-weight: 900;
	transition: 0.3s;
}

.file-upload-input {
	position: relative;
	overflow: hidden;
}

.file-upload-input {
	background-color: #e0f2e4;
	padding: 2.5rem 1rem;
	border: 2px dashed #28af4a;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer !important;
}

.border-info {
	border: 1px solid #398bf7 !important;
}

.border-info:hover {
	border: 1px solid #0a6ff3 !important;
}

table.dataTable tbody th,
table.dataTable tbody td {
	padding: 8px 10px;
	border: 1px solid #111 !important;

}

table.dataTable thead th,
table.dataTable thead td {
	padding: 10px 18px;
	border-bottom: 1px solid #111111;
	border: 1px solid #111 !important;

}

.table tbody th,
.table tbody td {
	padding: 0px 4px;
	border: 1px solid #111 !important;

}

.table thead th,
.table thead td {
	padding: 10px 18px;
	border-bottom: 1px solid #111111;
	border: 1px solid #111 !important;
}

.table thead th {
	font-weight: bold !important;
	text-transform: uppercase;
}

table.dataTable thead th {
	padding: 10px 18px;
	border-bottom: 1px solid #111111;
	text-transform: uppercase;
}

.odd {
	background-color: #f5f9f7 !important;
}

.even {
	background-color: white;
}

@media screen and (max-width: 599px) {
	.radio_container {
		width: 240px;
	}

	.mylabel {
		font-size: 14px;
		width: 127px;
	}
}

@media screen and (max-width: 1199px) {
	.mini-sidebar .topbar {
		width: 100% !important;
	}

	.page-wrapper {
		padding-top: 50px !important;
	}

	.md-drppicker.ltr {
		min-width: 100%;
	}
}

@media screen and (min-width:1200px) and (max-width:1350px) {
	.c-btn span span {
		width: 80%;
	}

	.c-btn span:first-child {
		width: 85%;
	}
}

.topbar .top-navbar .navbar-nav>.nav-item>.nav-link {
	padding-left: 4px !important;
	padding-right: 4px !important;
	margin-left: -5px !important;
}

.required {
	color: red !important;
	font-weight: bold;
}


.md-drppicker.drops-down-auto {
	top: 43px !important;
	left: 1px !important;
}

.date-picker .md-drppicker.drops-down-auto {
	top: 43px !important;
	left: -449px !important;
}

.md-drppicker.ltr {
	min-width: 618px;
	font-family: inherit;
}

.md-drppicker .ranges ul li button.active {
	background-color: #118dd2 !important;
}

.md-drppicker td.active,
.md-drppicker td.active:hover {
	background-color: #118dd2 !important;
}

.md-drppicker .btn {
	padding: 0 18px !important;
	background-color: #118dd2 !important;
	box-shadow: none !important;
	font-size: 15px;
	letter-spacing: 0.5px;
}

.md-drppicker .clear svg {
	width: 24px;
	margin-top: -10px;
	display: none;
}

.md-drppicker th {
	color: #666 !important;
	font-weight: 500 !important;
}

.md-drppicker .dropdowns+.dropdowns {
	width: 58px;
}

.md-drppicker .ranges ul li button:focus {
	outline: none;
}

.md-drppicker .ranges ul li button {
	font-size: 13px;
	padding: 8px 4px 8px 12px;
}

.md-drppicker th.prev,
.md-drppicker th.next {
	opacity: 0.6 !important;
}

.custom-upload-box .uploaded-img-box {
	position: relative;
	width: 110px;
	margin: 0 auto;
}

.custom-upload-box .uploaded-img-box img {
	height: auto;
	width: 110px;
	border-radius: 5px;
}

.custom-upload-box .uploaded-img-box span {
	position: absolute;
	top: -10px;
	right: -11px;
	width: 1;
	background: #fff;
	color: #306da1;
	width: 26px;
	line-height: 26px;
	height: 26px;
	font-weight: 400;
	border-radius: 50%;
	display: inline-block;
	font-size: 14px;
	cursor: pointer;
}

.custom-upload-box .upload-text {
	display: block;
	background: #cfecfe;
	padding: 10px;
	margin-bottom: 15px;
	border-radius: 5px;
	color: #444;
	font-weight: 600;
	cursor: pointer;
}

.custom-upload-box .upload-text:hover,
.custom-upload-box .upload-text:focus {
	background: #badcf1;
}

.custom-upload-box input[type=file] {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: auto;
	height: 41px;
	width: 100%;
	opacity: 0;
	cursor: pointer;
}

.file-upload-input.mini-file-upload-input {
	padding: 0.5rem 1rem;
}

.edit-image-box {
	width: 100px;
}

.edit-image-box i {
	margin-top: 1px;
	cursor: pointer;
}

.edit-image-box img {
	border-radius: 4px;
	width: 50px;
}

.edit-image-options {
	padding: 8px 8px;
	margin: 0;
	background: #f5f9f7;
	position: absolute;
	z-index: 9;
	width: 85px;
	box-shadow: 1px 2px 4px 1px #ededed
}

.edit-image-options li {
	list-style: none;
}

.edit-image-options li a {
	color: inherit;
	font-size: 13px;
	color: #5593c8;
	padding: 2px 0;
}

.edit-image-options li a:hover {
	color: #444;
}

.edit-image-options li a:focus {
	background-color: transparent;
	background: transparent;
}

.edit-image-options li a .fa {
	width: 12px;
}

.img-mini {
	width: 75px;
	height: auto;
	padding: 0.25rem;
	background-color: #ffffff;
	border: 1px solid #dee2e6;
	border-radius: 0.25rem;
	max-width: 100%;
}

body small,
body .small {
	font-size: 90%;
	font-weight: bold;
	margin-bottom: 3px;
	display: inline-block;
	color: #1f9be1;
}

.block-center {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.mw-250 {
	max-width: 250px;
	;
}

.card-body.cb-pad-adjust {
	/* padding-bottom: 0.75rem;
	padding-top: 1rem; */
	padding: 1rem !important;
}

.selected-list .c-list .c-token .c-remove {
	display: none;
}

.view-value {
	font-weight: 600;
	margin: 10px 0 0 0;
}

.sidebar-nav #sidebarnav>li>ul.collapse>li>a>.mdi {
	width: 28px;
}

.sidebartoggler i {
	width: 30px;
	height: 30px;
	line-height: 37px;
}

.left-sidebar {
	height: 100% !important;
}

.new-detail-page .form-group {
	margin-bottom: 0;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	;
}

.activity-history {
	max-height: 600px;
	overflow: auto;
}

.activity-history ul {
	margin: 0;
	padding: 0;
}

.activity-history ul li {
	list-style: none;
	margin-bottom: 18px;
}

.activity-history ul li:last-child {
	margin-bottom: 0px;
}

.activity-history ul li>div {
	margin: 0;
	display: block;
	color: #666;
	margin-bottom: 1px;
}

.activity-history ul li>div>span {
	width: 175px;
	display: inline-block;
}

.activity-history ul li i {
	font-size: 16px;
	color: #888;
	margin-right: 5px;
}

.activity-history ul li p {
	margin: 0;
	padding-left: 25px;
	color: #111;
}

table tr td .btn-td {
	min-width: 80px;
	font-size: 15px !important;
	height: 32px;
	line-height: 32px;
	padding: 0;
}

/* .table tbody td {
	overflow: hidden !important;
  } */
.w-75px {
	width: 75px !important;
}

.cursor-pointer {
	cursor: pointer;
}

.checkbox-lg {
	width: 20px;
	height: 20px;
	cursor: pointer;
}

.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus,
button:focus {
	outline: none !important;
}

.cuppa-dropdown .dropdown-list {
	position: absolute !important;
	top: 40px !important;
	bottom: 0 !important;
	left: auto !important;
}

.cuppa-dropdown.dropdown-list.arrow-down {
	display: none !important;
}

.arrow-down {
	bottom: -1px !important;
	top: unset;
	-webkit-transform: rotate(360deg) !important;
	transform: rotate(360deg) !important;
}

.arrow-down.arrow-2 {
	top: unset;
	bottom: 0px !important;
}

table.dataTable,
table.table,
table {
	border-collapse: collapse !important;
	box-sizing: border-box !important;
}

table.dataTable thead th,
.table thead th,
.table tr th {
	padding: 0 4px !important;
	background-color: #c1e3f6 !important;
	color: #000 !important;
	border-bottom: 1px solid #0f0f0fb0 !important;
	letter-spacing: 0.5px;
	font-weight: 400 !important;
	text-transform: uppercase !important;
}

.bg-calender-icon {
	background-image: url(assets/images/calendar-icon.svg);
	background-size: 16px;
	background-repeat: no-repeat;
	background-position-x: calc(100% - 8px);
	background-position-y: center;
}

.cuppa-dropdown .selected-list .c-list .c-token {
	position: relative !important;
	padding-left: 0 !important;
	max-width: calc(100% - 0px) !important;
	overflow: hidden !important;
}

form .cuppa-dropdown .selected-list .c-angle-down,
form .cuppa-dropdown .selected-list .c-angle-up {
	width: 10px !important;
	height: 12px !important;
	right: 8px !important;
	top: 42% !important;
}

@media (max-width: 768px) {

	.date-picker .md-drppicker.drops-down-auto {
		top: 43px !important;
		left: 1px !important;
	}
}


@media (min-width: 1168px) {
	.mini-sidebar .page-wrapper {
		margin-left: 70px !important;
	}
}

@media (max-width: 1167px) {
	.sidebartoggler {
		margin-left: 12px !important;
	}

	.topbar .top-navbar .navbar-header {
		padding-left: 12px !important;
	}

	.mini-sidebar ul.navbar-nav {
		padding-left: 15px;
	}
}

@media (min-width: 992px) {

	.new-detail-page .form-row .form-group:first-child,
	.new-detail-page .form-row .form-group:nth-child(2) {
		border-right: 1px dotted #999;
	}
}

@media (m-width: 768px) {

	.date-picker .md-drppicker.drops-down-auto {
		top: 43px !important;
		left: 1px !important;
	}
}

@media (max-width: 767px) {
	.mini-sidebar .topbar .top-navbar .navbar-header {
		width: 42px !important;
	}

	.topbar .top-navbar .navbar-header {
		width: 192px !important;
	}

	.mini-sidebar .navbar-collapse {
		flex-basis: auto !important;
	}

	.topbar .top-navbar a {
		width: 192px;
	}

	.topbar .top-navbar a img {
		width: 110px;
	}

	.mini-sidebar .topbar .top-navbar a img {
		width: 32px;
		height: 34px;
	}
}

.cdk-overlay-pane {
	position: fixed !important;
}

.mat-autocomplete-panel,
.mat-autocomplete-visible {
	background-color: #fff !important;
}

.mat-select-panel {
	background: white;
	box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
	background: rgba(0, 0, 0, 0.12);
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
	box-sizing: border-box;
	cursor: pointer;
	display: block;
	white-space: normal !important;
	overflow: hidden;
	text-overflow: ellipsis;
}

.ng-dropdown-panel {
	padding: 0 !important;
}

.ng-select .ng-clear-wrapper:hover {
	color: red !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items {
	background-color: #fff !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
	background-color: #fff !important;
	color: rgba(0, 0, 0, 0.87) !important;
	padding: 8px 10px !important;
}

.form-control:focus {
	color: #495057;
	background-color:none;
	border-color: none !important;
	outline: 0;
	outline-color: black !important;
	box-shadow: none !important;
	/* margin-top: 7px; */
}

.dropdown-toggle::after {
	display: none !important;
}



.table-responsive {
	padding-bottom: 10px !important;
}

.even {
	background-color: rgb(238, 242, 243);
}

.mat-autocomplete-panel:not([class*='mat-elevation-z']) {
	box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.nav-link {
	display: block;
	padding: 0.2rem 1rem !important;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
	color: #fff !important;
	background-color: #118dd2 !important;
	border-color: #dee2e6 #dee2e6 #ffffff;
}

.nav-tabs .nav-link {
	border: 1px solid white !important;
	background: #126da0 !important;
	color: #ffffff !important;
}

.c-btn span span {
	min-width: calc(100% - 20px);
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2 !important;
	line-clamp: 2;
	word-break: break-all;
	-webkit-box-orient: vertical !important;
	word-break: break-all;
	padding-right: 15% !important;
}

.c-btn span:first-child {
	width: 100% !important;
	font-size: 12.6px !important;
}

.clear-all {
	right: 23px !important;
}

.c-btn.disabled {
	/* background: #ccc; */
	background-color: #e9ecef !important;
}

.modal-backdrop {
	z-index: -1 !important;
}

.modal {
	background-color: #00000045 !important;
}

.img-rotate {
	position: absolute;
	top: 40%;
	left: 50%;
	width: 120px;
	height: 120px;
	margin: -60px 0 0 -60px;
	-webkit-animation: spin 1.2s linear infinite;
	-moz-animation: spin 1.2s linear infinite;
	animation: spin 1.2s linear infinite;
}

@-moz-keyframes spin {
	100% {
		-moz-transform: rotate(360deg);
	}
}

@-webkit-keyframes spin {
	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spin {
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@font-face {
	font-family: 'Pacifico';
	font-style: normal;
	font-weight: 400;
	src: local('Pacifico Regular'), local('Pacifico-Regular'),
		url(https://fonts.gstatic.com/s/pacifico/v12/FwZY7-Qmy14u9lezJ-6H6MmBp0u-.woff2) format('woff2');
	font-display: swap;
}
.font-s{
	font-size: 12.6px!important;
}
.total-padding{
	min-height: 35px !important;
}
.content-center-h{
	display: flex;
    flex-direction: column;
    justify-content: center;
}
.input-property {
	width: 93%;
	border: none;
	white-space: initial;
	text-overflow: ellipsis;
  }
  .card-body{
	padding: 1rem!important;
  }
  .mat-paginator-range-actions{
	padding-top: 16px;
  }
  .multiselect-item span {
  display: inline-block;
}


.multiselect-item .dot::before {
    content: '•';
    font-size: 50px; /* Adjust the size as needed */
  }
  .ng-star-inserted .multiselect-item .dot::before {
    content: '•';
    font-size: 50px; /* Adjust the size as needed */
  }
  