/******************* 
Global Styles 
*******************/

* {
    outline: none;
}

// @import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');




// @import url('https://fonts.googleapis.com/css2?family=Alkalami&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400&display=swap');

/* arabic */
@font-face {
    font-family: 'Alkalami';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/alkalami/v7/zOL_4pfDmqRL95WXi6eJycF8ug.woff2) format('woff2');
    unicode-range: U+0600-06FF, U+0750-077F, U+0870-088E, U+0890-0891, U+0898-08E1, U+08E3-08FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE70-FE74, U+FE76-FEFC;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Alkalami';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/alkalami/v7/zOL_4pfDmqRL95WXi6eCycF8ug.woff2) format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Alkalami';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/alkalami/v7/zOL_4pfDmqRL95WXi6eMycE.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* cyrillic-ext */
  @font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 400;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v36/memQYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWq8tWZ0Pw86hd0Rk8ZkWV0ewJER.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }
  /* cyrillic */
  @font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 400;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v36/memQYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWq8tWZ0Pw86hd0Rk8ZkWVQewJER.woff2) format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }
  /* greek-ext */
  @font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 400;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v36/memQYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWq8tWZ0Pw86hd0Rk8ZkWVwewJER.woff2) format('woff2');
    unicode-range: U+1F00-1FFF;
  }
  /* greek */
  @font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 400;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v36/memQYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWq8tWZ0Pw86hd0Rk8ZkWVMewJER.woff2) format('woff2');
    unicode-range: U+0370-03FF;
  }
  /* hebrew */
  @font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 400;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v36/memQYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWq8tWZ0Pw86hd0Rk8ZkWVIewJER.woff2) format('woff2');
    unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
  }
  /* vietnamese */
  @font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 400;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v36/memQYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWq8tWZ0Pw86hd0Rk8ZkWV8ewJER.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 400;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v36/memQYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWq8tWZ0Pw86hd0Rk8ZkWV4ewJER.woff2) format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 400;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v36/memQYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWq8tWZ0Pw86hd0Rk8ZkWVAewA.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* cyrillic-ext */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v36/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSKmu1aB.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }
  /* cyrillic */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v36/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSumu1aB.woff2) format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }
  /* greek-ext */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v36/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSOmu1aB.woff2) format('woff2');
    unicode-range: U+1F00-1FFF;
  }
  /* greek */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v36/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSymu1aB.woff2) format('woff2');
    unicode-range: U+0370-03FF;
  }
  /* hebrew */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v36/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS2mu1aB.woff2) format('woff2');
    unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
  }
  /* vietnamese */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v36/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSCmu1aB.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v36/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSGmu1aB.woff2) format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v36/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS-muw.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* cyrillic-ext */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v36/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSKmu1aB.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }
  /* cyrillic */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v36/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSumu1aB.woff2) format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }
  /* greek-ext */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v36/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSOmu1aB.woff2) format('woff2');
    unicode-range: U+1F00-1FFF;
  }
  /* greek */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v36/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSymu1aB.woff2) format('woff2');
    unicode-range: U+0370-03FF;
  }
  /* hebrew */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v36/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS2mu1aB.woff2) format('woff2');
    unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
  }
  /* vietnamese */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v36/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSCmu1aB.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v36/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSGmu1aB.woff2) format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v36/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS-muw.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* cyrillic-ext */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 500;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v36/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSKmu1aB.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }
  /* cyrillic */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 500;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v36/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSumu1aB.woff2) format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }
  /* greek-ext */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 500;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v36/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSOmu1aB.woff2) format('woff2');
    unicode-range: U+1F00-1FFF;
  }
  /* greek */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 500;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v36/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSymu1aB.woff2) format('woff2');
    unicode-range: U+0370-03FF;
  }
  /* hebrew */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 500;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v36/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS2mu1aB.woff2) format('woff2');
    unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
  }
  /* vietnamese */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 500;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v36/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSCmu1aB.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 500;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v36/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSGmu1aB.woff2) format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 500;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v36/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS-muw.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* cyrillic-ext */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v36/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSKmu1aB.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }
  /* cyrillic */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v36/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSumu1aB.woff2) format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }
  /* greek-ext */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v36/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSOmu1aB.woff2) format('woff2');
    unicode-range: U+1F00-1FFF;
  }
  /* greek */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v36/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSymu1aB.woff2) format('woff2');
    unicode-range: U+0370-03FF;
  }
  /* hebrew */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v36/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS2mu1aB.woff2) format('woff2');
    unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
  }
  /* vietnamese */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v36/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSCmu1aB.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v36/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSGmu1aB.woff2) format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v36/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS-muw.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* cyrillic-ext */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v36/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSKmu1aB.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }
  /* cyrillic */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v36/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSumu1aB.woff2) format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }
  /* greek-ext */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v36/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSOmu1aB.woff2) format('woff2');
    unicode-range: U+1F00-1FFF;
  }
  /* greek */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v36/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSymu1aB.woff2) format('woff2');
    unicode-range: U+0370-03FF;
  }
  /* hebrew */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v36/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS2mu1aB.woff2) format('woff2');
    unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
  }
  /* vietnamese */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v36/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSCmu1aB.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v36/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSGmu1aB.woff2) format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v36/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS-muw.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

body {
    background: #eaeaea;
    // font-family: $bodyfont;
    margin: 0;
    overflow-x: hidden;
    color: $bodytext;
    // font-weight: 600;
    font-size: 0.875rem;
    -webkit-font-smoothing: antialiased;
    // font-family: 'Plus Jakarta Sans', sans-serif;
    font-family: 'Open Sans', sans-serif;
    // letter-spacing: 0.7px !important;
    line-height: 16px;
}

html {
    position: relative;
    min-height: 100%;
    background: #eaeaea;
}

a:hover,
a:focus {
    text-decoration: none !important;
}

a {
    color: #118dd2;

    &:hover,
    &:focus {
        color: $headingtext;
    }
}

a.link {
    color: $headingtext;

    &:hover,
    &:focus {
        color: $themecolor;
    }
}

.img-responsive {
    width: 100%;
    height: auto;
    display: inline-block;
}

.img-rounded {
    border-radius: $radius;
}

html body .mdi:before,
html body .mdi-set {
    line-height: initial;
}

/*******************
Headings
*******************/

h1,
h2,
h3,
h4,
h5,
h6 {
    color: $headingtext;
    font-family: $headingfont;
    font-weight: 400;
}

h1 {
    line-height: 40px;
    font-size: 36px
}

h2 {
    line-height: 36px;
    font-size: 24px
}

h3 {
    line-height: 30px;
    font-size: 21px
}

h4 {
    line-height: 22px;
    font-size: 18px;
    font-weight: 600;
}

h5 {
    line-height: 18px;
    font-size: 16px;
    font-weight: 400;
}

h6 {
    line-height: 16px;
    font-size: 14px;
    font-weight: 400;
}

.display-5 {
    font-size: 3rem !important;
}

.display-6 {
    font-size: 36px;
}

.box {
    border-radius: $radius;
    padding: 10px;
}

html body {
    .dl {
        display: inline-block;
    }

    .db {
        display: block;
    }
}

.no-wrap {

    td,
    th {
        white-space: nowrap;
    }
}

/*******************
Blockquote
*******************/

html body blockquote {
    border-left: 5px solid $themecolor;
    border: 1px solid $border;
    padding: 15px;
}


/*******************
Paddings
*******************/

html body {
    .p-0 {
        padding: 0px;
    }

    .p-10 {
        padding: 10px;
    }

    .p-20 {
        padding: 20px;
    }

    .p-30 {
        padding: 30px;
    }

    .p-l-0 {
        padding-left: 0px;
    }

    .p-l-10 {
        padding-left: 10px;
    }

    .p-l-20 {
        padding-left: 20px;
    }

    .p-r-0 {
        padding-right: 0px;
    }

    .p-r-10 {
        padding-right: 10px;
    }

    .p-r-20 {
        padding-right: 20px;
    }

    .p-r-30 {
        padding-right: 30px;
    }

    .p-r-40 {
        padding-right: 40px;
    }

    .p-t-0 {
        padding-top: 0px;
    }

    .p-t-10 {
        padding-top: 10px;
    }

    .p-t-20 {
        padding-top: 20px;
    }

    .p-t-30 {
        padding-top: 30px;
    }

    .p-b-0 {
        padding-bottom: 0px;
    }

    .p-b-5 {
        padding-bottom: 5px;
    }

    .p-b-10 {
        padding-bottom: 10px;
    }

    .p-b-20 {
        padding-bottom: 20px;
    }

    .p-b-30 {
        padding-bottom: 30px;
    }

    .p-b-40 {
        padding-bottom: 40px;
    }
}





/*******************
Margin
*******************/

html body {
    .m-0 {
        margin: 0px;
    }

    .m-l-5 {
        margin-left: 5px;
    }

    .m-l-10 {
        margin-left: 10px;
    }

    .m-l-15 {
        margin-left: 15px;
    }

    .m-l-20 {
        margin-left: 20px;
    }

    .m-l-30 {
        margin-left: 30px;
    }

    .m-l-40 {
        margin-left: 40px;
    }

    .m-r-5 {
        margin-right: 5px;
    }

    .m-r-10 {
        margin-right: 10px;
    }

    .m-r-15 {
        margin-right: 15px;
    }

    .m-r-20 {
        margin-right: 20px;
    }

    .m-r-30 {
        margin-right: 30px;
    }

    .m-r-40 {
        margin-right: 40px;
    }

    .m-t-0 {
        margin-top: 0px;
    }

    .m-t-5 {
        margin-top: 5px;
    }

    .m-t-10 {
        margin-top: 10px;
    }

    .m-t-15 {
        margin-top: 15px;
    }

    .m-t-20 {
        margin-top: 20px;
    }

    .m-t-30 {
        margin-top: 30px;
    }

    .m-t-40 {
        margin-top: 40px;
    }

    .m-b-0 {
        margin-bottom: 0px;
    }

    .m-b-5 {
        margin-bottom: 5px;
    }

    .m-b-10 {
        margin-bottom: 10px;
    }

    .m-b-15 {
        margin-bottom: 15px;
    }

    .m-b-20 {
        margin-bottom: 20px;
    }

    .m-b-30 {
        margin-bottom: 30px;
    }

    .m-b-40 {
        margin-bottom: 40px;
    }
}

/*******************
font weight
*******************/

html body {
    .font-bold {
        font-weight: 700;
    }

    .font-normal {
        font-weight: normal;
    }

    .font-light {
        font-weight: 300;
    }

    .font-medium {
        font-weight: 500;
    }

    .font-16 {
        font-size: 16px;
    }

    .font-14 {
        font-size: 14px;
    }

    .font-10 {
        font-size: 10px;
    }

    .font-18 {
        font-size: 18px;
    }

    .font-20 {
        font-size: 20px;
    }
}

/*******************
Border
*******************/

html body {
    .b-0 {
        border: none;
    }

    .b-r {
        border-right: 1px solid $border;
    }

    .b-l {
        border-left: 1px solid $border;
    }

    .b-b {
        border-bottom: 1px solid $border;
    }

    .b-t {
        border-top: 1px solid $border;
    }

    .b-all {
        border: 1px solid $border !important;
    }
}



.hide {
    display: none;
}

.img-circle {
    border-radius: 100%;
}

.radius {
    border-radius: $radius;
}





/*******************
Text Colors
*******************/

.text-white {
    color: $white !important;
}

.text-danger {
    color: $danger !important;
}

.text-muted {
    color: $muted !important;
}

.text-warning {
    color: $warning !important;
}

.text-success {
    color: $success !important;
}

.text-info {
    color: $info !important;
}

.text-inverse {
    color: $inverse !important;
}

html body {
    .text-blue {
        color: $blue;
    }

    .text-purple {
        color: $purple;
    }

    .text-primary {
        color: $primary;
    }

    .text-megna {
        color: $megna;
    }

    .text-dark {
        color: $bodytext;
    }

    .text-themecolor {
        color: $themecolor;
    }
}





/*******************
Background Colors
*******************/

.bg-primary {
    background-color: $primary !important;
}

.bg-success {
    background-color: $success !important;
}

.bg-info {
    background-color: $info !important;
}

.bg-warning {
    background-color: $warning !important;
}

.bg-danger {
    background-color: $danger !important;
}

html body {
    .bg-megna {
        background-color: $megna;
    }

    .bg-theme {
        background-color: $themecolor;
    }

    .bg-inverse {
        background-color: $inverse;
    }

    .bg-purple {
        background-color: $purple;
    }

    .bg-light-part {
        background-color: $bglight;
    }

    .bg-light-primary {
        background-color: $light-primary;
    }

    .bg-light-success {
        background-color: $light-success;
    }

    .bg-light-info {
        background-color: $light-info;
    }

    .bg-light-extra {
        background-color: $extra-light;
    }

    .bg-light-warning {
        background-color: $light-warning;
    }

    .bg-light-danger {
        background-color: $light-danger;
    }

    .bg-light-inverse {
        background-color: $light-inverse;
    }

    .bg-light {
        background-color: $light;
    }

    .bg-white {
        background-color: $white;
    }
}





/*******************
Rounds
*******************/

.round {
    line-height: 48px;
    color: $white;
    width: 50px;
    height: 50px;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    border-radius: 100%;
    background: $info;

    img {
        border-radius: 100%;
    }
}

.round-lg {
    line-height: 65px;
    width: 60px;
    height: 60px;
    font-size: 30px;
}

.round.round-info {
    background: $info;
}

.round.round-warning {
    background: $warning;
}

.round.round-danger {
    background: $danger;
}

.round.round-success {
    background: $success;
}

.round.round-primary {
    background: $primary;
}

/*******************
Pagination
*******************/

.pagination>li:first-child>a,
.pagination>li:first-child>span {
    border-bottom-left-radius: $radius;
    border-top-left-radius: $radius;
}

.pagination>li:last-child>a,
.pagination>li:last-child>span {
    border-bottom-right-radius: $radius;
    border-top-right-radius: $radius;
}

.pagination>li>a,
.pagination>li>span {
    color: $dark;
}

.pagination>li>a:hover,
.pagination>li>span:hover,
.pagination>li>a:focus,
.pagination>li>span:focus {
    background-color: $light;
}

.pagination-split li {
    margin-left: 5px;
    display: inline-block;
    float: $lft;
}

.pagination-split li:first-child {
    margin-left: 0;
}

.pagination-split li a {
    -moz-border-radius: $radius;
    -webkit-border-radius: $radius;
    border-radius: $radius;
}

.pagination>.active>a,
.pagination>.active>span,
.pagination>.active>a:hover,
.pagination>.active>span:hover,
.pagination>.active>a:focus,
.pagination>.active>span:focus {
    background-color: $themecolor;
    border-color: $themecolor;
}

.pager li>a,
.pager li>span {
    -moz-border-radius: $radius;
    -webkit-border-radius: $radius;
    border-radius: $radius;
    color: $dark;
}






/*******************
Table Cell
*******************/

.table-box {
    display: table;
    width: 100%;
}

.table.no-border {
    tbody {
        td {
            border: 0px;
        }
    }
}

.cell {
    display: table-cell;
    vertical-align: middle;
}

.table td,
.table th {
    border-color: $table-border;
}

.table thead th,
.table th {
    font-weight: 500;
}

.table-hover tbody tr:hover {
    background: $light;
}

.lite-padding td {
    padding: 5px;
}

.v-middle {

    td,
    th {
        vertical-align: middle;
    }
}

.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}

/*Badge*/

.badge {
    font-weight: 400;
}

.badge-xs {
    font-size: 9px;
}

.badge-xs,
.badge-sm {
    -webkit-transform: translate(0, -2px);
    -ms-transform: translate(0, -2px);
    -o-transform: translate(0, -2px);
    transform: translate(0, -2px);
}

.badge-success {
    background-color: $success;
}

.badge-info {
    background-color: $info;
}

.badge-primary {
    background-color: $primary;
}

.badge-warning {
    background-color: $warning;
}

.badge-danger {
    background-color: $danger;
}

.badge-purple {
    background-color: $purple;
}

.badge-red {
    background-color: $red;
}

.badge-inverse {
    background-color: $inverse;
}

/*******************
Buttons
******************/

.btn {
    padding: 6px 12px;

    cursor: pointer;
}

.btn-group {
    label {
        color: $white !important;
        margin-bottom: 0px;

        &.btn-secondary {
            color: $bodytext !important;
        }
    }
}

.btn-lg {
    padding: .75rem 1.5rem;
    font-size: 1.25rem;
}

.btn-md {
    padding: 12px 55px;
    font-size: 16px;
}

.btn-circle {
    border-radius: 100%;
    width: 40px;
    height: 40px;
    padding: 10px;
}

.btn-circle.btn-sm {
    width: 35px;
    height: 35px;
    padding: 8px 10px;
    font-size: 14px;
}

.btn-circle.btn-lg {
    width: 50px;
    height: 50px;
    padding: 14px 15px;
    font-size: 18px;
    line-height: 22px;
}

.btn-circle.btn-xl {
    width: 70px;
    height: 70px;
    padding: 14px 15px;
    font-size: 24px;
}

.btn-sm {
    padding: .25rem .5rem;
    font-size: 12px;
}

.btn-xs {
    padding: .25rem .5rem;
    font-size: 10px;
}

.button-list button,
.button-list a {
    margin: 5px 12px 5px 0;
}

.btn-outline {
    color: inherit;
    background-color: transparent;
    transition: all .5s;
}

.btn-rounded {
    border-radius: 60px;
    padding: 7px 18px;

    &.btn-lg {
        padding: .75rem 1.5rem;
    }

    &.btn-sm {
        padding: .25rem .5rem;
        font-size: 12px;
    }

    &.btn-xs {
        padding: .25rem .5rem;
        font-size: 10px;
    }

    &.btn-md {
        padding: 12px 35px;
        font-size: 16px;
    }
}

.btn-secondary,
.btn-secondary.disabled {
    box-shadow: $default-shadow;
    transition: 0.2s ease-in;
    background-color: $white;
    color: $bodytext;
    border-color: $form-brd;

    &:hover {
        box-shadow: $default-shadow-hover;
        background: $light-secondary;
        border-color: $form-brd;
    }

    &.active,
    &:active,
    &:focus {
        box-shadow: $default-shadow-hover;
        background: $light-secondary;
        border-color: $form-brd;
    }
}

.btn-primary,
.btn-primary.disabled {
    background: $primary;
    border: 1px solid $primary;
    box-shadow: $primary-shadow;
    transition: 0.2s ease-in;

    &:hover {
        background: $primary;
        box-shadow: $primary-shadow-hover;
        border: 1px solid $primary;
    }

    &.active,
    &:active,
    &:focus {
        background: $primary-dark;
        box-shadow: $primary-shadow-hover;
    }
}

.btn-themecolor,
.btn-themecolor.disabled {
    background: $themecolor;
    color: $white;
    border: 1px solid $themecolor;

    &:hover {
        background: $themecolor;
        opacity: 0.7;
        border: 1px solid $themecolor;
    }

    &.active,
    &:active,
    &:focus {
        background: $themecolor-dark;
    }
}

.btn-success,
.btn-success.disabled {
    background: $success;
    border: 1px solid $success;
    box-shadow: none !important;
    transition: 0.2s ease-in;

    &:hover {
        background: $success;
        box-shadow: $success-shadow-hover;
        border: 1px solid $success;
    }

    &.active,
    &:active,
    &:focus {
        background: $success-dark;
        box-shadow: $success-shadow-hover;
    }
}

.btn-info,
.btn-info.disabled {
    background: $info;
    border: 1px solid $info;
    box-shadow: $info-shadow;
    transition: 0.2s ease-in;

    &:hover {
        background: $info;
        border: 1px solid $info;
        box-shadow: $info-shadow-hover;
    }

    &.active,
    &:active,
    &:focus {
        background: $info-dark;
        box-shadow: $info-shadow-hover;
    }
}

.btn-warning,
.btn-warning.disabled {
    background: $warning;
    color: $white;
    box-shadow: $warning-shadow;
    border: 1px solid $warning;
    transition: 0.2s ease-in;
    color: $white;

    &:hover {
        background: $warning;
        color: $white;
        box-shadow: $warning-shadow-hover;
        border: 1px solid $warning;
    }

    &.active,
    &:active,
    &:focus {
        background: $warning-dark;
        color: $white;
        box-shadow: $warning-shadow-hover;
    }
}

.btn-danger,
.btn-danger.disabled {
    background: $danger;
    border: 1px solid $danger;
    box-shadow: $danger-shadow;
    transition: 0.2s ease-in;

    &:hover {
        background: $danger;
        box-shadow: $danger-shadow-hover;
        border: 1px solid $danger;
    }

    &.active,
    &:active,
    &:focus {
        background: $danger-dark;
        box-shadow: $danger-shadow-hover;
    }
}

.btn-inverse,
.btn-inverse.disabled {
    background: $inverse;
    border: 1px solid $inverse;
    color: $white;

    &:hover {
        background: $inverse;
        opacity: 0.7;
        color: $white;
        border: 1px solid $inverse;
    }

    &.active,
    &:active,
    &:focus {
        background: $inverse-dark;
        color: $white;
    }
}

.btn-red,
.btn-red.disabled {
    background: $red;
    border: 1px solid $red;
    color: $white;

    &:hover {
        opacity: 0.7;
        border: 1px solid $red;
        background: $red;
    }

    &.active,
    &:active,
    &:focus {
        background: $danger-dark;
    }
}

.btn-outline-secondary {
    background-color: $white;
    box-shadow: $default-shadow;
    transition: 0.2s ease-in;

    &:hover,
    &:focus,
    &.focus {
        box-shadow: $default-shadow-hover;
    }

    &.active,
    &:active,
    &:focus {
        box-shadow: $default-shadow-hover;
    }
}

.btn-outline-primary {
    color: $primary;
    background-color: $white;
    border-color: $primary;
    box-shadow: $primary-shadow;
    transition: 0.2s ease-in;

    &:hover,
    &:focus,
    &.focus {
        background: $primary;
        box-shadow: $primary-shadow-hover;
        color: $white;
        border-color: $primary;
    }

    &.active,
    &:active,
    &:focus {
        box-shadow: $primary-shadow-hover;
        background: $primary-dark;
    }
}

.btn-outline-success {
    color: $success;
    background-color: transparent;
    border-color: $success;
    box-shadow: $success-shadow;
    transition: 0.2s ease-in;

    &:hover,
    &:focus,
    &.focus {
        background: $success;
        border-color: $success;
        color: $white;
        box-shadow: $success-shadow-hover;
    }

    &.active,
    &:active,
    &:focus {
        box-shadow: $success-shadow-hover;
        background: $success-dark;
    }
}

.btn-outline-info {
    color: #fff;
    background-color: #018b5d;
    border-color: #018b5d;
    box-shadow: none;
    transition: 0.2s ease-in;

    &:hover,
    &:focus,
    &.focus {
        background: #016644 !important;
        border-color: #016644 !important;
        color: $white;
        box-shadow: none;
    }

    &.active,
    &:active,
    &:focus {
        background: #016644 !important;
        border-color: #016644 !important;
        box-shadow: $info-shadow-hover;
        background: $info-dark;
    }
}

.btn-outline-warning {
    color: $warning;
    background-color: transparent;
    border-color: $warning;
    box-shadow: $warning-shadow;
    transition: 0.2s ease-in;

    &:hover,
    &:focus,
    &.focus {
        background: $warning;
        border-color: $warning;
        color: $white;
        box-shadow: $warning-shadow-hover;
    }

    &.active,
    &:active,
    &:focus {
        box-shadow: $warning-shadow-hover;
        background: $warning-dark;
    }
}

.btn-outline-danger {
    color: $danger;
    background-color: transparent;
    border-color: $danger;
    box-shadow: $danger-shadow;
    transition: 0.2s ease-in;

    &:hover,
    &:focus,
    &.focus {
        background: $danger;
        border-color: $danger;
        color: $white;
        box-shadow: $danger-shadow-hover;
    }

    &.active,
    &:active,
    &:focus {
        box-shadow: $danger-shadow-hover;
        background: $danger-dark;
    }
}

.btn-outline-red {
    color: $red;
    background-color: transparent;
    border-color: $red;

    &:hover,
    &:focus,
    &.focus {
        background: $red;
        border-color: $red;
        color: $white;
        box-shadow: $danger-shadow-hover;
    }

    &.active,
    &:active,
    &:focus {
        box-shadow: $danger-shadow-hover;
        background: $danger-dark;
    }
}

.btn-outline-inverse {
    color: $inverse;
    background-color: transparent;
    border-color: $inverse;

    &:hover,
    &:focus,
    &.focus {
        background: $inverse;
        border-color: $inverse;
        color: $white;
    }
}

.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary.focus:active,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open>.dropdown-toggle.btn-primary.focus,
.open>.dropdown-toggle.btn-primary:focus,
.open>.dropdown-toggle.btn-primary:hover,
.btn-primary.focus,
.btn-primary:focus {
    background-color: $primary-dark;
    border: 1px solid $primary-dark;
}

.btn-success.active.focus,
.btn-success.active:focus,
.btn-success.active:hover,
.btn-success.focus:active,
.btn-success:active:focus,
.btn-success:active:hover,
.open>.dropdown-toggle.btn-success.focus,
.open>.dropdown-toggle.btn-success:focus,
.open>.dropdown-toggle.btn-success:hover,
.btn-success.focus,
.btn-success:focus {
    background-color: $success-dark;
    border: 1px solid $success-dark;
}

.btn-info.active.focus,
.btn-info.active:focus,
.btn-info.active:hover,
.btn-info.focus:active,
.btn-info:active:focus,
.btn-info:active:hover,
.open>.dropdown-toggle.btn-info.focus,
.open>.dropdown-toggle.btn-info:focus,
.open>.dropdown-toggle.btn-info:hover,
.btn-info.focus,
.btn-info:focus {
    background-color: $info-dark;
    border: 1px solid $info-dark;
}

.btn-warning.active.focus,
.btn-warning.active:focus,
.btn-warning.active:hover,
.btn-warning.focus:active,
.btn-warning:active:focus,
.btn-warning:active:hover,
.open>.dropdown-toggle.btn-warning.focus,
.open>.dropdown-toggle.btn-warning:focus,
.open>.dropdown-toggle.btn-warning:hover,
.btn-warning.focus,
.btn-warning:focus {
    background-color: $warning-dark;
    border: 1px solid $warning-dark;
}

.btn-danger.active.focus,
.btn-danger.active:focus,
.btn-danger.active:hover,
.btn-danger.focus:active,
.btn-danger:active:focus,
.btn-danger:active:hover,
.open>.dropdown-toggle.btn-danger.focus,
.open>.dropdown-toggle.btn-danger:focus,
.open>.dropdown-toggle.btn-danger:hover,
.btn-danger.focus,
.btn-danger:focus {
    background-color: $danger-dark;
    border: 1px solid $danger-dark;
}

.btn-inverse:hover,
.btn-inverse:focus,
.btn-inverse:active,
.btn-inverse.active,
.btn-inverse.focus,
.btn-inverse:active,
.btn-inverse:focus,
.btn-inverse:hover,
.open>.dropdown-toggle.btn-inverse {
    background-color: $inverse-dark;
    border: 1px solid $inverse-dark;
}

.btn-red:hover,
.btn-red:focus,
.btn-red:active,
.btn-red.active,
.btn-red.focus,
.btn-red:active,
.btn-red:focus,
.btn-red:hover,
.open>.dropdown-toggle.btn-red {
    background-color: $red-dark;
    border: 1px solid $red-dark;
    color: $white;
}

.button-box .btn {
    margin: 0 8px 8px 0px;
}

.apply-btn {
    padding: 5px 12px !important;
    cursor: pointer;
}

.btn-label {
    background: rgba(0, 0, 0, 0.05);
    display: inline-block;
    margin: -6px 12px -6px -14px;
    padding: 7px 15px;
}

.btn-facebook {
    color: $white;
    background-color: #3b5998;
}

.btn-twitter {
    color: $white;
    background-color: #55acee;
}

.btn-linkedin {
    color: $white;
    background-color: #007bb6;
}

.btn-dribbble {
    color: $white;
    background-color: #ea4c89;
}

.btn-googleplus {
    color: $white;
    background-color: #dd4b39;
}

.btn-instagram {
    color: $white;
    background-color: #3f729b;
}

.btn-pinterest {
    color: $white;
    background-color: #cb2027;
}

.btn-dropbox {
    color: $white;
    background-color: #007ee5;
}

.btn-flickr {
    color: $white;
    background-color: #ff0084;
}

.btn-tumblr {
    color: $white;
    background-color: #32506d;
}

.btn-skype {
    color: $white;
    background-color: #00aff0;
}

.btn-youtube {
    color: $white;
    background-color: #bb0000;
}

.btn-github {
    color: $white;
    background-color: #171515;
}

/*******************
List-style-none
******************/

ul.list-style-none {
    margin: 0px;
    padding: 0px;

    li {
        list-style: none;

        a {
            color: $bodytext;
            padding: 8px 0px;
            display: block;
            text-decoration: none;

            &:hover {
                color: $themecolor;
            }
        }
    }
}

/*******************
dropdown-item
******************/

.dropdown-item {
    padding: 8px 1rem;
    color: $bodytext;
}

/*******************
Custom-select
******************/

.custom-select {
    background: url(../../assets/images/custom-select.png) right .75rem center no-repeat, #ffffff;
}

/*******************
Form-control
******************/

.form-control {
    color: $bodytext;
    min-height: 36px;
    display: initial;
    background-color: #fefefe;
    border: solid 1px #ccc;
}

.form-control-sm {
    min-height: 20px;
}

.form-control:disabled,
.form-control[readonly] {
    opacity: 0.7;
}

.custom-control-input:focus~.custom-control-indicator {
    box-shadow: none;
}

.custom-control-input:checked~.custom-control-indicator {
    background-color: $success;
}

form label {
    font-weight: 400;
}

.form-group {
    margin-bottom: 15px;
}

.form-horizontal label {
    margin-bottom: 0px;
}

.form-control-static {
    padding-top: 0px;
}

.form-bordered .form-group {
    border-bottom: 1px solid $border;
    padding-bottom: 20px;
}


/*******************
Layouts
*****************/

/*Card-noborders*/

.card-no-border {
    .card {
        // border: 2px solid #ccdcee;
        border-radius: $radius;
        box-shadow: 0 5px 15px 0 rgba(0, 0, 0, .1);
        border: none;
    }
}

.card-no-border .shadow-none {
    box-shadow: none;
}

.card-outline-danger,
.card-outline-info,
.card-outline-warning,
.card-outline-success,
.card-outline-primary {
    background: $white;
}

.card-fullscreen {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    overflow: auto;
}

.fix-width {
    width: 100%;
    max-width: 1170px;
    margin: 0 auto;
}

/*******************/
/*card-default*/
/*******************/

.card-default {
    .card-header {
        background: $white;
        border-bottom: 0px;
    }
}

/*******************/
/*pace-js*/
/*******************/

.pace {
    -webkit-pointer-events: none;
    pointer-events: none;

    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

.pace-inactive {
    display: none;
}

.pace .pace-progress {
    background: $danger;
    position: fixed;
    z-index: 2000;
    top: 0;
    right: 100%;
    width: 100%;
    height: 2px;
}

.progress {
    height: auto;
    min-height: 6px;
}

.no-control-indicator {

    .carousel-indicators,
    .carousel-control-prev,
    .carousel-control-next {
        display: none;
    }
}

.lstick {
    width: 2px;
    background: $themecolor;
    height: 30px;
    margin-left: -20px;
    margin-right: 18px;
    display: inline-block;
    vertical-align: middle;
}