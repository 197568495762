/*******************
Main sidebar
******************/

.left-sidebar {
    position: fixed;
    width: 240px;
    height: 100vh;
    top: 0px;
    left:0px;
    z-index: 20;
    padding-top: 60px;
    background: $sidebar;
    // box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 0px 15px rgb(0 0 0 / 23%);
}

.fix-sidebar .left-sidebar {
    position: fixed;
}

/*******************
sidebar navigation
******************/

.scroll-sidebar {
    height: calc(100vh - 60px);
    position: relative;
    &.ps .ps__rail-y {
        left: 2px;
        right: auto;
        background: none;
        width: 6px;
        /* If using `left`, there shouldn't be a `right`. */
    }
}

.collapse.in {
    display: block !important;
}
.nav-small-cap {
        font-size: 12px;
        margin-bottom: 0px;
        padding: 14px 14px 14px 20px;
        font-weight: 500;
    }
.sidebar-nav {
    padding: 0px;
    ul {
        margin: 0px;
        // padding: 10px 0px 10px 0px;
        padding: 10px 12px 10px 12px;
        li {
            list-style: none;
            transition: 0.2s ease-in-out;
            margin-bottom: 5px;
            border-radius: 7px !important;
            font-family: 'Plus Jakarta Sans', sans-serif;
            a {
                // color: #000;
                color: #2a3547;
                padding: 6px 15px 6px 15px;
                display: block;
                font-size: 14px;
                font-weight: 400;
                transition: 0.2s ease-in-out;
                border-radius: 7px !important;
                
                &.active,
                &:hover {
                    color: $white;
                    i {
                        color: $white;
                    }
                }
                &.active {
                    font-weight: 500;
                    color: $dark;
                    border-radius: 7px !important;
                }
            }
            ul {
                padding-left: 10px;
                background: rgba(255,255,255,.1);
                ul {
                    padding-left: 10px;
                }
            }
            &.nav-devider {
                height: 1px;
                background: $border;
                display: block;
                margin: 15px 0;
            }
        }
    }
    > ul > li > a {
        i {
            width: 31px;
            font-size: 24px;
            display: inline-block;
            vertical-align: middle;
            color: $white;
        }
        .label {
            float: right;
            margin-top: 6px;
        }
        &.active {
            font-weight: 400;
            background: #118dd2;
            
            color: $white;
            // border-radius: 100px 0 0 100px;
        }
    }
    > ul > li {
        &.active > a {
            color: $bodytext;
            font-weight: 400;
            i {
                color: $bodytext;
            }
        }
    }
}